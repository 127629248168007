






























































































































































import {Component, Prop, Watch} from 'vue-property-decorator'
import NftCollectionTable from './nfts/NftCollectionTable.vue'
import Swiper from 'swiper'
import {MixinScreenSize} from './mixins/MixinScreenSize'
import PoweredBy, {PoweredByItem} from '@/components/PoweredBy.vue'
import LastPriceUpdated from '@/components/LastPriceUpdated.vue'
import ShowAllCollectionsToggle from '@/components/ShowAllCollectionsToggle.vue'
import ExploreTokensLastPriceUpdated from '@/components/homepage/explore-tokens-and-nfts/ExploreTokensLastPriceUpdated.vue'
import ExploreTokensPoweredBy from '@/components/homepage/explore-tokens-and-nfts/ExploreTokensPoweredBy.vue'
import {NftCollectionCollection} from '@/model/collection/NftCollectionCollection'
import {SimpleListNftCollectionSchema} from '@/schema/resource/NftCollection/SimpleListNftCollectionSchema'
import TokensPriceScrollableTable from '@/components/TokensPriceScrollableTable.vue'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'
import {$} from '@/facade'

@Component({
  components: {
    ExploreTokensPoweredBy,
    ExploreTokensLastPriceUpdated,
    ShowAllCollectionsToggle,
    NftCollectionTable,
    PoweredBy,
    LastPriceUpdated,
    TokensPriceScrollableTable,
  },
})
export default class TokenNftTable extends MixinScreenSize {
  @Prop({type: String, default: 'tokens'}) selectedTable!: string

  tokensTableItemsCollection = new TokensTableItemsCollection()
  nftCollection = new NftCollectionCollection()
  schema = new SimpleListNftCollectionSchema()

  lastTimeUpdated = ''
  itemsPerPage = 6

  tokensPoweredByList = [
    {
      url: 'https://flamingo.finance/',
      alt: 'flamingo icon',
      filename: 'flamingo-icon.svg',
    } as PoweredByItem,
    {
      url: 'https://www.forthewin.network/#/',
      alt: 'ftwicon',
      filename: 'for-the-win-logo.svg',
    } as PoweredByItem,
    {
      url: 'https://neofura-1.gitbook.io/api-docs/',
      alt: 'magnet icon',
      filename: 'magnet-icon.svg',
    } as PoweredByItem,
    {
      url: 'https://carrot-fi.xyz/',
      alt: 'carrot swap icon',
      filename: 'carrot-swap.svg',
    } as PoweredByItem,
  ]

  nftsPoweredByList = [
    {
      url: 'https://ghostmarket.io/',
      alt: 'ghostmarket icon',
      filename: 'ghostmarket-icon.svg',
    } as PoweredByItem,
  ]

  isBeginning = true
  isEnd = true

  swiper!: Swiper

  get swiperOption() {
    return {
      pagination: {
        el: '.token-nft-swiper-pagination',
        clickable: true,
      },
      spaceBetween: 30,
    }
  }

  async created() {
    this.applyResources()
    await this.populateResources()
  }

  applyResources() {
    this.nftCollection.showBoughtInOneMonth = true
    this.nftCollection.orderBy = 'order'
    this.nftCollection.asc = false
    this.nftCollection.perPage = this.nftCollection.total
    this.nftCollection.perPage = this.itemsPerPage
    this.nftCollection.isVisible = true
    this.nftCollection.minOrder = 1

    this.tokensTableItemsCollection.orderBy = 'order'
    this.tokensTableItemsCollection.asc = false
    this.tokensTableItemsCollection.minOrder = 1
    this.tokensTableItemsCollection.perPage = this.itemsPerPage
  }

  async populateResources() {
    const promises: Promise<unknown>[] = [
      this.nftCollection.expand(),
      this.tokensTableItemsCollection.queryAsPage(),
    ]

    await $.await.run('loadCollection', async () => await Promise.all(promises))
  }

  @Watch('tokensTableItemsCollection.items')
  onTokensTableItemsCollectionItemsChange() {
    this.lastTimeUpdated = this.tokensTableItemsCollection.lastTimeUpdatedFormatted
  }

  async initSwiper(swiper: Swiper) {
    this.swiper = swiper
    this.updateEvent()
  }

  updateEvent() {
    this.isBeginning = this.swiper.isBeginning
    this.isEnd = this.swiper.isEnd
  }

  prevSlide() {
    if (!this.swiper.isBeginning) {
      this.swiper.slidePrev()
    }
    this.updateEvent()
  }

  nextSlide() {
    if (!this.swiper.isEnd) {
      this.swiper.slideNext()
    }
    this.updateEvent()
  }
}
